import React from "react"
import CaseStudiesModule from "./caseStudiesModule"
import PageContainer from "../../../components/pageContainer"
import styled from "styled-components"
import BasicButton from "../../common/buttons/basicButton"
import {graphql, useStaticQuery} from "gatsby"
import { LocalizationContext } from "../../../layout/layout"

const StyledPageContainerWrapper = styled.div`
  overflow-y: hidden;
`

const StyledLatestWrapper = styled.div`
  position: relative;
`

const StyledOthersWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
`
const StyledPLatest = styled.p`
  font-size: 14px;
  line-height: 30px;
  display: block;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.mainTextNavyBlue};
  ::before {
    content: '';
    position: absolute;
    width: 5px;
    left: 0px;
    top: 13px;
    height: 5px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.mainTextLightBlue};
  }
`


const StyledPOther = styled.p`
  font-size: 14px;
  line-height: 30px;
  display: block;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.mainTextNavyBlue};
  ::before {
    content: '';
    position: absolute;
    width: 5px;
    left: 0px;
    top: 13px;
    height: 5px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.mainNavyBlue};
  }
`

const StyledAllImplementations = styled.div`
  position: relative;
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
`

class CaseStudiesModules extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: false }

    this.showMoreCaseStudies = this.showMoreCaseStudies.bind(this)
  }

  showMoreCaseStudies = () => {
    this.setState({ show: true })
  }

  render() {
    const { localize } = this.props
    const entries = this.props.entries || ""
    const globalDependsOnLang = localize.lang === "pl" ? this.props.globalData.pl.nodes || "" : this.props.globalData.en.nodes || ""
    const globalData = globalDependsOnLang[0] || ""
    const firstEntry = entries[0]
    const otherEntries = entries.slice(1, 3)
    const loadMoreEntries = entries.slice(3, entries.length)
    const entriesLength = entries.length
    const latestImplementation = globalData.archeeSlowoLatestImplementation || ""
    const allImplementations = globalData.archeeSlowoAllImplementations || ""
    const loadMoreButton = globalData.archeePrzyciskLoadMore || ""


    let buttonVisibilityOnStart
    entriesLength <= 3 ? buttonVisibilityOnStart = false : buttonVisibilityOnStart = true

    return (
      <StyledPageContainerWrapper>
        <PageContainer>
          <StyledLatestWrapper>
            <StyledPLatest>{latestImplementation}</StyledPLatest>
            <CaseStudiesModule entry={firstEntry} latest={true}/>
          </StyledLatestWrapper>
          <StyledAllImplementations>
            <StyledPOther>{allImplementations}</StyledPOther>
          </StyledAllImplementations>
          <StyledOthersWrapper>
            {otherEntries.map((otherEntry, key) => (
              <CaseStudiesModule entry={otherEntry} key={key} latest={false}/>
            ))}
            {this.state.show && loadMoreEntries.map((loadMoreEntry, key) => (
              <CaseStudiesModule entry={loadMoreEntry} key={key} latest={false}/>
            ))}
          </StyledOthersWrapper>
          {!(this.state.show) && <BasicButton onClick={this.showMoreCaseStudies}
                                              className={buttonVisibilityOnStart ? "btn-casestudiesbottom" : "btn-case-studies-hide"}>{loadMoreButton}</BasicButton>}
        </PageContainer>
      </StyledPageContainerWrapper>
    )
  }
}

const LocalizedCaseStudiesModules = React.forwardRef((props, ref) => {
  const data = useStaticQuery(graphql`
    query{
      pl: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "pl" } }) {
        nodes {
          archeeSlowoLatestImplementation
          archeeSlowoAllImplementations
          archeePrzyciskLoadMore
        }
      }
      en: allCraftArcheeOgolneTlumaczeniaGlobalSet(filter: { language: { eq: "en" } }) {
        nodes {
          archeeSlowoLatestImplementation
          archeeSlowoAllImplementations
          archeePrzyciskLoadMore
        }
      }
    }`);
  return (
      <LocalizationContext.Consumer>
        {localize => <CaseStudiesModules {...props} globalData={data} localize={localize} ref={ref}/>}
      </LocalizationContext.Consumer>
  )
})

export default LocalizedCaseStudiesModules;
